import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_JEWELLERY_SHOWCASE_VIDEOS_URL = `${API_URL}api/v1/auth/admin/jewellery-showcase`
export const GET_SEARCH_JEWELLERY_SHOWCASE_VIDEOS_URL = `${API_URL}api/v1/auth/admin/jewelleryShowcaseVideo`
export const CHANGE_STATUS_JEWELLERY_SHOWCASE_VIDEO_URL = `${API_URL}api/v1/auth/admin/jewelleryShowcaseVideo/status`
export const CHANGE_BLACKLIST_STATUS_JEWELLERY_SHOWCASE_VIDEO_URL = `${API_URL}api/v1/auth/admin/jewelleryShowcaseVideo/black-list`
export const CREATE_JEWELLERY_SHOWCASE_VIDEO_URL = `${API_URL}api/v1/auth/admin/jewellery-showcase`
export const EDIT_JEWELLERY_SHOWCASE_VIDEO_URL = `${API_URL}api/v1/auth/admin/jewelleryShowcaseVideo`
export const DELETE_JEWELLERY_SHOWCASE_VIDEO_URL = `${API_URL}api/v1/auth/admin/jewellery-showcase`
export const FETCH_API_STOCK_URL = `${API_URL}api/v1/auth/admin/jewelleryShowcaseVideo/fetch-api-stocks`

interface ListType {
  count?: any
  data: any
}

// Get USers Data
export async function getJewelleryShowcaseVideosData(startIndex: number, limitNumber: number) {
  try {
    let jewelleryShowcaseVideosData: ListType = await axios.get(
      `${GET_JEWELLERY_SHOWCASE_VIDEOS_URL}?skip=${startIndex}&limit=${limitNumber}`
    )
    return jewelleryShowcaseVideosData
  } catch (e) {
    throw e
  }
}

export async function getSearchJewelleryShowcaseVideosData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let jewelleryShowcaseVideosData: ListType = await axios.get(
      `${GET_SEARCH_JEWELLERY_SHOWCASE_VIDEOS_URL}?skip=${startIndex}&limit=${limitNumber}&q=${searchText}`
    )
    return jewelleryShowcaseVideosData
  } catch (e) {
    throw e
  }
}

// Change Status User Data
export async function changeStatusJewelleryShowcaseVideoData(id: string, flag: boolean) {
  try {
    let jewelleryShowcaseVideoData = await axios.put(
      `${CHANGE_STATUS_JEWELLERY_SHOWCASE_VIDEO_URL}`,
      {
        id,
        is_active: flag,
      }
    )
    return jewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}

export async function changeBlackListData(id: string, flag: boolean) {
  try {
    let jewelleryShowcaseVideoData = await axios.put(
      `${CHANGE_BLACKLIST_STATUS_JEWELLERY_SHOWCASE_VIDEO_URL}`,
      {
        id,
        is_blacklisted: flag,
      }
    )
    return jewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}
// Create User Data
export async function createJewelleryShowcaseVideoData(jewelleryShowcaseVideoObject: any) {
  try {
    let createJewelleryShowcaseVideoData = await axios.post(
      `${CREATE_JEWELLERY_SHOWCASE_VIDEO_URL}`,
      {
        ...jewelleryShowcaseVideoObject,
      }
    )
    return createJewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}

// Edit User Data
export async function editJewelleryShowcaseVideoData(jewelleryShowcaseVideoObject: any) {
  try {
    let editJewelleryShowcaseVideoData = await axios.put(`${EDIT_JEWELLERY_SHOWCASE_VIDEO_URL}`, {
      ...jewelleryShowcaseVideoObject,
    })
    return editJewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}

// Delete User Data
export async function deleteJewelleryShowcaseVideoData(id: string) {
  try {
    let deleteJewelleryShowcaseVideoData = await axios.delete(
      `${DELETE_JEWELLERY_SHOWCASE_VIDEO_URL}?id=${id}`
    )
    return deleteJewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}

// JewelleryShowcaseVideo Details and Terms Condition Data
export async function jewelleryShowcaseVideoDetailsAndTermsConditionData(
  jewelleryShowcaseVideoObject: any
) {
  try {
    let editJewelleryShowcaseVideoData = await axios.put(`${EDIT_JEWELLERY_SHOWCASE_VIDEO_URL}`, {
      ...jewelleryShowcaseVideoObject,
    })
    return editJewelleryShowcaseVideoData
  } catch (e) {
    throw e
  }
}

export async function fetchJewelleryShowcaseVideoStockData(id: any) {
  try {
    let fetchStock = await axios.get(`${FETCH_API_STOCK_URL}?id=${id}`)
    return fetchStock
  } catch (e) {
    throw e
  }
}
