import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_STOCK_OFFER_URL = `${API_URL}api/v1/auth/admin/stock-offer`
export const STOCK_OFFER_UPDATE_URL = `${API_URL}api/v1/auth/admin/update-offer`

interface ListType {
  count?: any
  data: any
}

// Get Offer Data
export async function getClientOfferData(
  startIndex: number,
  limitNumber: number,
  status?: string,
  is_response_required?: boolean
) {
  try {
    let getOrderAPIPath = ''
    if (!status) {
      return {
        data: [],
      }
    }
    let offerData: ListType = await axios.get(
      `${GET_STOCK_OFFER_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}&is_response_required=${
        is_response_required ? is_response_required : false
      }`
    )
    return offerData
  } catch (e) {
    throw e
  }
}

export async function clientOfferActionApi(clientOfferActionObj: any) {
  delete clientOfferActionObj?.price_per_caret
  try {
    let clientOfferData = await axios.put(`${STOCK_OFFER_UPDATE_URL}`, {
      ...clientOfferActionObj,
    })
    return clientOfferData
  } catch (e) {
    throw e
  }
}
