import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {clientOfferActionApi} from '../redux/ClientOfferCRUD'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'

interface IRenderFormikForm {
  values: IOfferFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IOfferFormValues {
  offer_id: string
  status: string
  offer_price: number
  price_per_caret: number
}

interface IOfferPriceProps {
  data?: any
  handleCancel?: any
  role: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class NewOfferPriceForm extends React.Component<IOfferPriceProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IOfferFormValues) => {
    const errors: any = {}

    if (!values.offer_price) {
      errors.offer_price = 'Offer Price is Required'
    }

    return errors
  }
  // public setFieldBaseOnWeight = (data) => {
  //   console.log({data})

  // }

  public handleSubmit = async (values: IOfferFormValues, action: any) => {
    try {
      let offerData = await clientOfferActionApi(values)
      if (offerData && offerData.status == 200) {
        Toaster({
          type: 'success',
          title: 'STOCK OFFER',
          description: 'Offer Price Updated Successfully',
        })
      }

      this.props.handleCancel()
    } catch (e) {
      Toaster({
        type: 'error',
        title: 'STOCK OFFER',
        description: 'Somenthing Went Wrong',
      })
      console.log(e)
    } finally {
      this.props.handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data, role} = this.props
    console.log({data})
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  offer_id: data.id ? data.id : '',
                  status: 'PENDING',
                  offer_price:
                    role == 'vendor'
                      ? data.updated_offer_price
                        ? data.updated_offer_price.toFixed(2)
                        : NaN
                      : data.offer_price
                      ? data.offer_price.toFixed(2)
                      : NaN,
                  price_per_caret:
                    role === 'vendor'
                      ? data.updated_offer_price_per_caret
                        ? data.updated_offer_price_per_caret
                        : NaN
                      : data.offer_price_per_caret
                      ? data.offer_price_per_caret
                      : NaN,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='row'>
                        {/* <div className='col-md-4 col-12'>
                          <div className='d-flex flex-column' style={{marginBottom: '20px'}}>
                            <div
                              style={{
                                fontWeight: 600,
                                letterSpacing: '0.5px',
                                color: '#000',
                              }}
                            >
                              BASE PRICE
                            </div>
                            <div>{data.base_price ? data.base_price.toFixed(2) : '-'}</div>
                          </div>
                        </div> */}
                        <div className='col-md-4 col-12'>
                          <div className='d-flex flex-column' style={{marginBottom: '20px'}}>
                            <div
                              style={{
                                fontWeight: 600,
                                letterSpacing: '0.5px',
                                color: '#000',
                              }}
                            >
                              UPDATED OFFER PRICE
                            </div>
                            <div className='col-sm-6 col-12'>
                              {role == 'vendor'
                                ? data.updated_offer_price
                                  ? data.updated_offer_price.toFixed(2)
                                  : NaN
                                : data.offer_price
                                ? data.offer_price.toFixed(2)
                                : NaN}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 col-12'>
                          <InputNumberText
                            input={{
                              value: values.offer_price,
                              id: 'offer_price',
                              name: 'offer_price',
                            }}
                            placeholder='New Offer Price'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('offer_price', value)
                              setFieldValue('price_per_caret', (value / data.stock.weight).toFixed(2))
                            }}
                            onBlur={handleBlur}
                            label='New Offer Price'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-4 col-12'>
                          <InputNumberText
                            input={{
                              value: values.price_per_caret,
                              id: 'price_per_caret',
                              name: 'price_per_caret',
                            }}
                            placeholder='Price Per Caret'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('price_per_caret', value)
                              setFieldValue('offer_price', (value * data.stock.weight).toFixed(2))

                              // this.setFieldBaseOnWeight({price_per_caret: value})
                            }}
                            onBlur={handleBlur}
                            label='Price Per Caret'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mt-4'>
                        <InputButtonComponent onClick={handleSubmit}>Update</InputButtonComponent>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
