/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as jewelleryShowcaseVideos from '../redux/JewelleryShowcaseVideosRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {
  changeBlackListData,
  changeStatusJewelleryShowcaseVideoData,
  deleteJewelleryShowcaseVideoData,
  editJewelleryShowcaseVideoData,
  getSearchJewelleryShowcaseVideosData,
  getJewelleryShowcaseVideosData,
  createJewelleryShowcaseVideoData,
} from '../redux/JewelleryShowcaseVideosCRUD'
import {config} from '../../../comman/constants'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
// import {EditJewelleryShowcaseVideoForm} from './EditJewelleryShowcaseVideoForm'
// import FtpDetails from './FtpDetails'
// import {EditUserForm} from './EditUserForm'
// import {IClientFormValues} from './CreateClientForm'

interface IRecordsTableProps {
  jewelleryShowcaseVideosData: any
  jewelleryShowcaseVideosDataTotal: number
  jewelleryShowcaseVideosLoading: boolean
  setListJewelleryShowcaseVideosData: (jewelleryShowcaseVideosData: any) => void
  setListJewelleryShowcaseVideosDataTotal: (jewelleryShowcaseVideosDataTotal: number) => void
  setJewelleryShowcaseVideosLoading: (jewelleryShowcaseVideosLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  jewelleryShowcaseVideosSelectedData: any
  ftpDataState: any
  copied: any
  copyCell: any
  showAddVideoModal: boolean
}

type PathParamsType = {
  param1: string
}
class ListJewelleryShowcaseVideosData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      jewelleryShowcaseVideosSelectedData: null,
      ftpDataState: null,
      copied: false,
      copyCell: {},
      showAddVideoModal: false,
    }
  }

  public uploadMedia = async (fileName: string, FileData: Array<any>, customFileData: Object) => {
    const {startIndex, limitNumber} = this.state

    if (fileName && FileData.length >= 1) {
      console.log('!!!!!!!!!!!uploadMedia', FileData, fileName)
      await createJewelleryShowcaseVideoData({file_name: fileName})
    }
    //close modal popup
    this.setState({
      showAddVideoModal: false,
      // isImageLinked: false,
      // image_url: `${appAPIURL}banner/image/${fileName}`,
      // file_name: fileName,
    })
    this.listJewelleryShowcaseVideosData(startIndex, limitNumber)
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListJewelleryShowcaseVideosData(pagination)
    )
  }

  public getListJewelleryShowcaseVideosData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listJewelleryShowcaseVideosData(start, end)
      }
    )
  }

  public listJewelleryShowcaseVideosData = async (start: number, end: any) => {
    const {setJewelleryShowcaseVideosLoading} = this.props
    try {
      const {setListJewelleryShowcaseVideosData, setListJewelleryShowcaseVideosDataTotal} =
        this.props
      await setJewelleryShowcaseVideosLoading(true)
      let jewelleryShowcaseVideosData = await getJewelleryShowcaseVideosData(start, end)
      if (jewelleryShowcaseVideosData.data) {
        await setListJewelleryShowcaseVideosData(jewelleryShowcaseVideosData.data)
        await setListJewelleryShowcaseVideosDataTotal(jewelleryShowcaseVideosData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setJewelleryShowcaseVideosLoading(false)
    }
  }

  



  public onCreateJewelleryShowcaseVideo = () => {
    this.setState({
      showAddVideoModal: true,
    })
  }

  public deleteJewelleryShowcaseVideo = async (item: any) => {
    const {setJewelleryShowcaseVideosLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setJewelleryShowcaseVideosLoading(true)
      await deleteJewelleryShowcaseVideoData(item.id)

      Toaster({
        type: 'success',
        title: 'JEWELLERY SHOWCASE VIDEOS',
        description: 'Deleted SuccessFully',
      })
      this.listJewelleryShowcaseVideosData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setJewelleryShowcaseVideosLoading(false)
    }
  }




  public closeModal = async () => {
    this.setState({
      showAddVideoModal: false,
    })
  }



  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Video',
      key: 'Video',
      width: 150,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div className='d-flex'>
            {item.video_url ? (
              <video src={item.video_url} controls width='200' height='150' className='rounded' />
            ) : (
              <span className='text-muted'>No Video</span>
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 180,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete jewelleryShowcaseVideos api
                    this.deleteJewelleryShowcaseVideo(item)
                  }}
                  title='Are you sure you want to delete jewelleryShowcaseVideos?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {
      jewelleryShowcaseVideosData,
      jewelleryShowcaseVideosDataTotal,
      jewelleryShowcaseVideosLoading,
      loginUser,
    } = this.props
    const {role} = loginUser

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header' style={{justifyContent: 'flex-end'}}>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateJewelleryShowcaseVideo()
                  }}
                >
                  Add Jewellery Showcase Video
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: jewelleryShowcaseVideosDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={jewelleryShowcaseVideosLoading}
                  dataSource={jewelleryShowcaseVideosData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  // scrollWidth={role == 'super_admin' ? 1350 : 1200}
                />
                <div>
                  {' '}
                  <CommanModal
                    show={this.state.showAddVideoModal ? true : false}
                    handleClose={() => {
                      this.closeModal()
                    }}
                    title={'Add video'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      <InputSingleFileUpload
                        // ref={(e: any) => {
                        //   this.viewFileUploadModalRef = e
                        // }}
                        buttonLabel={'Choose Video File'}
                        useModal={false}
                        emptyListOnUpload={true}
                        onBlur={() => {}}
                        error={() => {}}
                        touched={() => {}}
                        name='file_name'
                        input={{
                          id: 'import-video',
                          name: 'video',
                          action: config.addJewelleryVideoPath,
                          headers: {
                            authorization: 'authorized-text',
                          },
                          accept: '.mp4, .mov, .avi, .mkv, .webm', // Only video file extensions
                          acceptMimeTypes: [
                            'video/mp4',
                            'video/quicktime',
                            'video/x-msvideo',
                            'video/x-matroska',
                            'video/webm',
                          ], // Video MIME types
                          // accept: '.png,.jpeg,.jpg,.gif,.webp',
                          // acceptMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                          mediaType: config.importStockUploadKey,
                          mediaModuleType: config.importStockUploadKey,
                        }}
                        onUpload={(
                          file_name: string,
                          FileData: Array<any>,
                          customFileData: Object
                        ) => {
                          this.uploadMedia(file_name, FileData, customFileData)
                          // setFieldValue(
                          //   'file_name',
                          //   file_name && FileData.length >= 1 ? file_name : ''
                          // )
                        }}
                      />
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  jewelleryShowcaseVideosData: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosData,
  jewelleryShowcaseVideosDataTotal: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosDataTotal,
  jewelleryShowcaseVideosLoading: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListJewelleryShowcaseVideosData: (jewelleryShowcaseVideosData: any) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosData(jewelleryShowcaseVideosData)
      )
    },
    setListJewelleryShowcaseVideosDataTotal: (jewelleryShowcaseVideosDataTotal: number) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosDataTotal(
          jewelleryShowcaseVideosDataTotal
        )
      )
    },
    setJewelleryShowcaseVideosLoading: (jewelleryShowcaseVideosLoading: boolean) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosLoading(
          jewelleryShowcaseVideosLoading
        )
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListJewelleryShowcaseVideosData))
